<template>
  <div class="collection-live-editor flex grow flex-col text-sm lg:overflow-hidden">
    <div class="flex grow flex-col lg:overflow-hidden">
      <div class="grow lg:overflow-y-auto">
        <Accordion v-model="tabs">
          <AccordionItem :title="$t('personal_details')">
            <div class="mx-auto max-w-xl space-y-4 md:py-4 lg:py-0">
              <div class="grid gap-4 md:grid-cols-2 md:gap-8 lg:grid-cols-1 lg:gap-4">
                <Form :data="firstNameForm" method="PUT" endpoint="user" autosave @saving="onSaving(1)" @success="(response) => onSuccess(1, response)">
                  <InputGroup :label="$t('first_name')" name="first_name">
                    <TextInput v-model="firstNameForm.first_name" name="first_name" @update:model-value="onFirstNameChange" />
                  </InputGroup>
                </Form>

                <Form :data="lastNameForm" method="PUT" endpoint="user" autosave @saving="onSaving(2)" @success="(response) => onSuccess(2, response)">
                  <InputGroup :label="$t('last_name')" name="last_name">
                    <TextInput v-model="lastNameForm.last_name" name="last_name" @update:model-value="onLastNameChange" />
                  </InputGroup>
                </Form>
              </div>

              <Form :data="phoneForm" method="PUT" endpoint="user" autosave @saving="onSaving(3)" @success="(response) => onSuccess(3, response)">
                <InputGroup :label="$t('phone_number')" name="phone">
                  <TextInput v-model="phoneForm.phone" name="phone" @update:model-value="onPhoneChange" />
                </InputGroup>
              </Form>

              <Form :data="logoForm" method="PUT" endpoint="user" autosave @saving="onSaving(4)" @success="(response) => onSuccess(4, response)">
                <InputGroup :label="$t('logo')" name="logo">
                  <FileInput v-model="logoForm.logo" name="logo" @update:model-value="onLogoChange" />
                </InputGroup>
              </Form>

              <Form :data="personalPhotoForm" method="PUT" endpoint="user" autosave @saving="onSaving(5)" @success="(response) => onSuccess(5, response)">
                <InputGroup :label="$t('personal_photo')" name="personal_photo">
                  <FileInput v-model="personalPhotoForm.personal_photo" name="personal_photo" @update:model-value="onPersonalPhotoChange" />
                </InputGroup>
              </Form>
            </div>
          </AccordionItem>

          <AccordionItem :title="$t('customize_content_experience')">
            <div class="mx-auto max-w-xl space-y-4 md:py-4 lg:py-0">
              <Form :data="personalMessageForm" method="PUT" :endpoint="endpoint" autosave @saving="onSaving(6)" @success="onSuccess(6)">
                <InputGroup :label="$t('personal_message')" name="personal_message">
                  <TextInput v-model="personalMessageForm.personal_message" name="personal_message" textarea @update:model-value="onPersonalMessageChange" />
                </InputGroup>
              </Form>

              <Form
                v-if="ctaEnabled && ctaOptions.length > 1"
                :data="ctaDisplayForm"
                method="PUT"
                :endpoint="endpoint"
                autosave
                @saving="onSaving(7)"
                @success="onSuccess(7)"
              >
                <InputGroup :label="$t('call_to_action_button')" name="cta_display">
                  <RadioGroup
                    v-model="ctaDisplayForm.cta_display"
                    name="cta_display"
                    type="small-stack"
                    :options="ctaOptions"
                    @update:model-value="onCtaDisplayChange"
                  />
                </InputGroup>
              </Form>

              <Form
                v-if="ctaEnabled && ctaDisplay === 'custom'"
                :data="ctaLabelForm"
                method="PUT"
                :endpoint="endpoint"
                autosave
                @saving="onSaving(8)"
                @success="onSuccess(8)"
              >
                <InputGroup name="cta_label">
                  <TextInput v-model="ctaLabelForm.cta_label" name="cta_label" :placeholder="$t('button_text')" @update:model-value="onCtaLabelChange" />
                </InputGroup>
              </Form>

              <Form
                v-if="ctaEnabled && ctaDisplay === 'custom'"
                :data="ctaLinkForm"
                method="PUT"
                :endpoint="endpoint"
                autosave
                @saving="onSaving(9)"
                @success="onSuccess(9)"
              >
                <InputGroup name="cta_link">
                  <TextInput v-model="ctaLinkForm.cta_link" name="cta_link" placeholder="https://" @update:model-value="onCtaLinkChange" />
                </InputGroup>
              </Form>
            </div>
          </AccordionItem>
        </Accordion>
      </div>
      <div v-show="isSaving || saved" class="mt-auto border-t p-8" :class="{ 'bg-green-50': saved }">
        <div class="mx-auto max-w-xl" :class="saved ? 'text-green-500' : 'text-supplement'">
          <span class="flex items-center space-x-2.5 font-medium">
            <Icon v-if="saved" icon="check" class="text-lg" />
            <Icon v-else icon="spinner-third" prefix="duotone" spin class="text-lg" />
            <span>{{ saved ? "Saved" : "Saving..." }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { CtaDisplay, Media, User } from "~/types";

const props = defineProps<{
  sharedCollectionHashid: string;
  firstName: string | null;
  lastName: string | null;
  phone: string | null;
  logo: Media | null;
  personalPhoto: Media | null;
  personalMessage: string | null;
  ctaDisplay: CtaDisplay;
  ctaLink: string | null;
  ctaLabel: string | null;
  ctaEnabled: boolean;
  ctaOptions: { label: string; value: CtaDisplay }[];
}>();

const emit = defineEmits([
  "update:firstName",
  "update:lastName",
  "update:phone",
  "update:logo",
  "update:personalPhoto",
  "update:personalMessage",
  "update:ctaDisplay",
  "update:ctaLink",
  "update:ctaLabel",
]);

const endpoint = `/collections/${props.sharedCollectionHashid}/personalisations/${getVersionHashidFromUrl()}`;

// Localise CTA options
const { t } = useI18n();
const ctaOptions = computed(() => props.ctaOptions.map((option) => ({ label: t(option.value), value: option.value })));

// Tabs
const activeTab = props.firstName ? 2 : "all";
const tabs = ref<number | "all" | null>(activeTab);

// Forms
const firstNameForm = reactive({ first_name: props.firstName });
const lastNameForm = reactive({ last_name: props.lastName });
const phoneForm = reactive({ phone: props.phone });
const logoForm = reactive({ logo: props.logo });
const personalPhotoForm = reactive({ personal_photo: props.personalPhoto });
const personalMessageForm = reactive({ personal_message: props.personalMessage });
const ctaDisplayForm = reactive({ cta_display: props.ctaDisplay });
const ctaLinkForm = reactive({ cta_link: props.ctaLink });
const ctaLabelForm = reactive({ cta_label: props.ctaLabel });

const onFirstNameChange = (firstName: string) => emit("update:firstName", firstName);
const onLastNameChange = (lastName: string) => emit("update:lastName", lastName);
const onPhoneChange = (phone: string) => emit("update:phone", phone);
const onLogoChange = (logo: Media | null) => emit("update:logo", logo);
const onPersonalPhotoChange = (personalPhoto: Media | null) => emit("update:personalPhoto", personalPhoto);
const onPersonalMessageChange = (personalMessage: string | null) => emit("update:personalMessage", personalMessage);
const onCtaDisplayChange = (ctaDisplay: CtaDisplay) => emit("update:ctaDisplay", ctaDisplay);
const onCtaLinkChange = (ctaLink: string | null) => emit("update:ctaLink", ctaLink);
const onCtaLabelChange = (ctaLabel: string | null) => emit("update:ctaLabel", ctaLabel);

// Saving indicator
const saving = ref<{ [formId: string]: boolean }>({});
const isSaving = computed(() => Object.keys(saving.value).length > 0);
const onSaving = (formId: number) => (saving.value[formId] = true);

const user = useUser();

const onSuccess = (formId: number, response?: { data: User }) => {
  delete saving.value[formId];

  if (response) {
    user.value = response.data;
  }
};

const saved = ref(false);
const timer = ref();

watch(isSaving, (value) => {
  if (value) {
    saved.value = false;
  } else {
    if (timer.value) {
      clearTimeout(timer.value);
    }

    saved.value = true;
    timer.value = setTimeout(() => {
      saved.value = false;
      timer.value = null;
    }, 1500);
  }
});
</script>

<style lang="postcss">
.collection-live-editor {
  > fieldset {
    @apply flex grow flex-col overflow-hidden;
  }

  .accordion {
    .accordion-item__header {
      @apply px-6;
    }

    .accordion-item--active .accordion-item__content {
      @apply lg:p-6;
    }
  }
}
</style>
